import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from 'app/main/cart/service/cart.service';
import { HasCartService } from 'app/service/has-cart/has-cart.service';
import { MetaTagsService, TruncateType } from 'app/service/meta-tags/meta-tags.service';
import { PersonalizationService } from 'app/service/personalization/personalization.service';
import { SearchSessionEventService } from 'app/service/search-session/search-session-event.service';
import { hasRolesSelector } from 'app/store/user/user.selector';
import { getTranslationFromStream } from 'app/utils/operator/get-translation-from-stream';
import { Utils } from 'app/utils/utils';
import { RolesEnum } from 'app/vo/roles/roles';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { AppState } from '../../app.state';
import { MARKETPLACE_NAVIGATION } from '../../navigation/navigation-routes/common/marketplace.navigation';
import { Action, AuthorizationEcomService } from '../../service/authorization-ecom/authorization-ecom.service';
import { CatalogSidebarService } from '../../service/catalog-sidebar/catalog-sidebar.service';
import { AddProductToCatalogService } from '../../service/catalog/add-product-to-catalog.service';
import { EcomVO } from '../../service/ecom/ecom.service';
import { ProductViewGtmAction } from '../../service/google-tag-manager/actions/product_view';
import { ViewItemGtmAction } from '../../service/google-tag-manager/actions/view_item';
import { GtmManagerService } from '../../service/google-tag-manager/gtm-manager.service';
import { ExploreProductsService } from '../../service/marketplace/explore-products/explore-products.service';
import { MarketplaceEcomService } from '../../service/marketplace/marketplace-ecom/marketplace-ecom.service';
import { NavigationService } from '../../service/navigation/navigation.service';
import { SupplierGatewayService } from '../../service/suppliers/supplier-gateway.service';
import { SupplierTaskService } from '../../service/suppliers/supplier-task.service';
import { SupplierOrderInfoData } from '../../shared/components/supplier-order-info/supplier-order-info.component';
import { ProductImageListPipe } from '../../shared/pipes/product-image-list.pipe';
import { isAuthenticatedSelector } from '../../store/authentication/authentication.selector';
import { selectedCurrencySelector } from '../../store/currency/currency.selector';
import { BRAND_NUMBER, PRODUCT_NUMBER } from '../../utils/marketing-constants';
import { CategoryVo } from '../../vo/category-vo';
import { SearchProductVO, VariantVO } from '../../vo/search-product-vo';
import { SupplierWithBadgesDto } from '../../vo/supplier/supplier-with-badges-dto';
import { AuthenticationManagerService } from '../authentication/authentication-manager.service';
import { SnippetEnum } from '../setup-guide/enums/snippet-enums';
import { SetupGuideService } from '../setup-guide/service/setup-guide.service';
import { ProductAccordionData } from './product-page-details-accordion/product-page-details-accordion.component';
import { ProductPageImageViewerComponent } from './product-page-image-viewer/product-page-image-viewer.component';
import { ProductDetailsPageService } from './service/product-details-page-service.service';
import { NotificationService } from '../notification/notification.service';

@Component({
  selector: 'app-product-details-page',
  templateUrl: './product-details-page.component.html',
  styleUrls: ['./product-details-page.component.scss'],
  providers: [ProductImageListPipe],
})
export class ProductDetailsPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('imageViewerComponent') imageSelector: ProductPageImageViewerComponent;
  @ViewChild('ctaList') ctaListRef: TemplateRef<any>;
  loading = true;
  error = false;
  selectedEcom: EcomVO;
  product: SearchProductVO;
  productCategory: number;
  supplier: SupplierWithBadgesDto;
  storeFrontBaseUrl = `/${MARKETPLACE_NAVIGATION.STOREFRONT}/`;
  supplierSeoUrl: string;
  optionNameFields: [string, any][];
  ecomCurrency = 'USD';
  selectedVariant: VariantVO;
  quantity = 1;
  isMobileSize = false;
  hasAccessToContacts = false;
  accordionData: ProductAccordionData;
  orderInfoData: SupplierOrderInfoData;

  readonly ctaItems: { key: string; values?: { [key: string]: string | number } }[] = [
    { key: 'PRODUCT_PAGE.CTA.ITEMS.ITEM_1', values: { number: PRODUCT_NUMBER.FULL_STRING } },
    { key: 'PRODUCT_PAGE.CTA.ITEMS.ITEM_2', values: { number: BRAND_NUMBER.FULL_STRING } },
    { key: 'PRODUCT_PAGE.CTA.ITEMS.ITEM_3' },
  ];
  private readonly _unsubscribeAll: Subject<void>;

  constructor(
    private _exploreProductService: ExploreProductsService,
    private route: ActivatedRoute,
    private marketplaceEcomService: MarketplaceEcomService,
    private addProductToCatalog: AddProductToCatalogService,
    private supplierGateway: SupplierGatewayService,
    private authorizationService: AuthorizationEcomService,
    private supplierTaskService: SupplierTaskService,
    private fuseConfigService: FuseConfigService,
    private store: Store<AppState>,
    private productDetailsService: ProductDetailsPageService,
    private cartService: CartService,
    private catalogSidebarService: CatalogSidebarService,
    public hasCartService: HasCartService,
    private setupGuideService: SetupGuideService,
    private authenticationManagerService: AuthenticationManagerService,
    private personalizationService: PersonalizationService,
    public metaTagsService: MetaTagsService,
    private searchSessionEventService: SearchSessionEventService,
    private productImageListPipe: ProductImageListPipe,
    private navigationService: NavigationService,
    private gtmManagerService: GtmManagerService,
    private translateService: TranslateService,
    private _notificationService: NotificationService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.init();
    this.sendProductViewActionIntoGTM();
    this.subscribeToBreakpointObserver();
    this.subscribeToEcomChange();
    this.initByAuthenticatedUser();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.subscribeToInitChanges();
  }

  private init(): void {
    this.store
      .select(isAuthenticatedSelector)
      .pipe(
        switchMap((isLoggedIn) =>
          isLoggedIn ? this.marketplaceEcomService.getEcomWithSubscriptionFromStore() : of(null)
        ),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((ecomVO: EcomVO) => {
        this.selectedEcom = ecomVO;
        this.initProductPageState();
        this.initByEcom();
      });
  }

  private initByEcom(): void {
    if (!!this.selectedEcom) {
      this.fetchUsedCatalogs();
    }
  }

  private initByAuthenticatedUser(): void {
    this.authorizationService
      .isAuthenticatedUser()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((loggedIn) => {
        if (loggedIn) {
          this.setupGuideService.setCompletedStep(SnippetEnum.RETAILER_EXPLORE_PRODUCTS);
        }
      });
  }

  private initProductPageState(): void {
    this.getCurrency();
    this.checkIfHasContactAccess().subscribe((value) => {
      this.hasAccessToContacts = value;
    });
    this.getQueryParams()
      .pipe(
        switchMap((productId) => {
          return this.getProduct(productId, this.getEcomId(this.selectedEcom));
        }),
        tap((product) => {
          this.handleProductResponse(product);
        }),
        switchMap((product) => {
          return this.getSupplierByUserId(product.USER_ID);
        }),
        tap((supplier) => (this.supplier = supplier)),
        tap((supplier) => {
          const userId = supplier?.userId ?? this.product.USER_ID;
          const supplierName = supplier?.handle ? supplier.handle : this.product.SUPPLIER.companyName;
          this.supplierSeoUrl = this.navigationService.getStorefrontPath(userId, supplierName);
        })
      )
      .subscribe({
        next: () => {
          this.error = false;
          this.loading = false;
        },
        error: () => {
          this.error = true;
          this.loading = false;
        },
      });
  }

  private getCurrency(): void {
    this.store.select(selectedCurrencySelector).subscribe((currency) => {
      this.ecomCurrency = currency;
    });
  }

  private getEcomId(selectedEcom: EcomVO): number {
    if (selectedEcom) {
      return selectedEcom.id;
    } else {
      return null;
    }
  }

  getIsRetailer(): Observable<boolean> {
    return this.store.select(hasRolesSelector, RolesEnum.RETAILER);
  }

  handleQuantityChange(value: number): void {
    this.quantity = value;
  }

  checkIfHasContactAccess(): Observable<boolean> {
    return this.authorizationService.hasPermissionObs(Action.CONTACTS_READ);
  }

  handleVariantChange(variant: VariantVO): void {
    this.selectedVariant = variant;
    this.sendViewItemActionIntoGTM(variant);
    if (this.imageSelector) {
      const variantImages = variant.V_IMAGES;
      const mainImageSelector = this.imageSelector.imageViewer;
      const thumbnailImageViewer = this.imageSelector.thumbnail;
      if (variantImages && variantImages.length) {
        const matchingImage = this.imageSelector.productImages.find((image) => variantImages[0] === image);
        if (matchingImage) {
          thumbnailImageViewer?.slick.slickGoTo(thumbnailImageViewer.images.indexOf(matchingImage));
          mainImageSelector?.slick.slickGoTo(mainImageSelector.images.indexOf(matchingImage));
        }
      }
    }
  }

  private getQueryParams(): Observable<string> {
    return this.route.params.pipe(
      takeUntil(this._unsubscribeAll),
      map((value) => {
        return value.id;
      })
    );
  }

  private getProduct(productId: string, ecomId: number): Observable<SearchProductVO> {
    return this._exploreProductService.getProductByID(productId, ecomId).pipe(
      takeUntil(this._unsubscribeAll),
      map((result) => {
        return result.products[0];
      })
    );
  }

  private handleProductResponse(product: SearchProductVO): void {
    this.addMetaTags(product);
    this.product = product;
    this.sendProductClickEvent(product);
    this.addToHistory(product);
    this.productCategory = product.SYNCEE_CATEGORY ? product.SYNCEE_CATEGORY[0] : null;
    // this.selectedVariant = product.VARIANTS[0];
    this.handleVariantChange(product.VARIANTS[0]);
    this.getOptionNameFields(product);
    this.getDetailsForAccordion(product, this.ecomCurrency, this.optionNameFields);
    this.getOrderInfoDataForAccordion(product, this.hasAccessToContacts);
    this.productDetailsService.subscribeToCategories().subscribe((tree) => {
      if (tree) {
        this.setBreadcrumbs(tree, product.SYNCEE_CATEGORY ? product.SYNCEE_CATEGORY[0] : null, product.TITLE);
      }
    });
  }

  public toNumber(value: string): number {
    return parseInt(value, 10);
  }
  private getSupplierByUserId(userId: number): Observable<SupplierWithBadgesDto> {
    return this.supplierGateway.getSuppliers([userId]).pipe(map((supplier) => supplier[0]));
  }

  private getOptionNameFields(product: SearchProductVO): void {
    this.optionNameFields = this.productDetailsService.getOptionNameFields(product);
  }

  openShippingDialog(): void {
    this.productDetailsService.openShippingDialog(this.product);
  }

  addToImportList(): void {
    this.addProductToCatalog.add(this.product);
  }

  private fetchUsedCatalogs(): void {
    this.catalogSidebarService.getRetailerToCatalogList();
  }

  getDetailsForAccordion(product: SearchProductVO, ecomCurrency: string, optionNameFields: [string, any][]): void {
    this.accordionData = this.productDetailsService.getDetailsForAccordion(product, ecomCurrency, optionNameFields);
  }

  getOrderInfoDataForAccordion(product: SearchProductVO, hasAccessToContacts: boolean): void {
    this.orderInfoData = this.productDetailsService.getOrderInfoDataForAccordion(product, hasAccessToContacts);
  }

  private subscribeToEcomChange(): void {
    this.marketplaceEcomService.onDomainChange.pipe(takeUntil(this._unsubscribeAll)).subscribe((ecom) => {
      if (!Utils.isNullOrUndefined(ecom.ecomId) && !Utils.isNullOrUndefined(this.product)) {
        this.ecomCurrency = this.marketplaceEcomService.getCurrency();
        this.selectedEcom = ecom;
        this.initProductPageState();
      }
    });
  }

  subscribeToBreakpointObserver(): void {
    this.productDetailsService.subscribeToBreakpointObserver().subscribe((value) => {
      this.isMobileSize = value.matches;
    });
  }

  private handleCtaClicked(): void {
    this.authenticationManagerService.emitOpenRegistrationDialog();
  }

  private subscribeToInitChanges(): void {
    combineLatest([this.store.select(isAuthenticatedSelector), this.getQueryParams()])
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(([loggedIn]) => this.initTheme(loggedIn));
  }

  private initTheme(loggedIn: boolean): void {
    this.translateService
      .stream('PRODUCT_PAGE.CTA')
      .pipe(getTranslationFromStream('PRODUCT_PAGE.CTA'))
      .subscribe({
        next: (translations) => {
          this.fuseConfigService.config = {
            layout: {
              navbar: { hidden: true },
              contentContainer: 'base-container',
              breadcrumb: {
                hidden: false,
                path: [],
              },
              footer: {
                ...(!loggedIn && {
                  cta: {
                    title: translations?.['TITLE'],
                    buttonText: translations?.['BUTTON_TEXT'],
                    descriptionTemplateRef: this.ctaListRef,
                    callback: this.handleCtaClicked.bind(this),
                  },
                }),
              },
            },
          };
        },
      });
  }

  private setBreadcrumbs(categoryTree: CategoryVo, categoryId: number, productName: string): void {
    this.fuseConfigService.config = this.productDetailsService.setBreadcrumbs(
      categoryTree,
      categoryId ?? null,
      productName ?? null
    );
  }
  addToCart(): void {
    const requestData = this.cartService.buildCartRequestDto(
      this.supplier.userId,
      this.selectedVariant.VARIANT_ID,
      this.quantity,
      'EN'
    );
    this.cartService.addToCart(requestData).subscribe(() => {
      this._notificationService.success(this.translateService.instant('PRODUCT_PAGE.ADDED_TO_CART'));
    });
  }

  private addMetaTags(product: SearchProductVO): void {
    this.metaTagsService.updateTags(
      product.TITLE,
      product.DESCRIPTION,
      this.productImageListPipe.transform(product)[0],
      location.href,
      TruncateType.PRODUCT
    );
  }

  private addToHistory(product: SearchProductVO): void {
    this.personalizationService.addProductToCookie(product);
  }

  private sendProductClickEvent(product: SearchProductVO): void {
    this.searchSessionEventService.sendProductClickEvent(product.ID);
  }

  private sendViewItemActionIntoGTM(variant: VariantVO): void {
    this.gtmManagerService.pushTag(
      new ViewItemGtmAction({
        currency: this.product.SUPPLIER.paymentCurrency,
        value: variant.DEFAULT_CURRENCY_PRICE,
        items: [
          {
            item_id: this.product.ID,
            item_name: this.product.TITLE,
            item_brand: this.product.BRAND,
            item_category: this.productCategory.toString(),
            item_variant: variant.VARIANT_ID,
            price: variant.DEFAULT_CURRENCY_PRICE,
          },
        ],
      })
    );
  }

  private sendProductViewActionIntoGTM(): void {
    this.gtmManagerService.pushTag(new ProductViewGtmAction());
  }
}
