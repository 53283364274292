import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImageTemplateSelectorModule } from 'app/main/image-template-selector/image-template-selector.module';
import { NotificationService } from 'app/main/notification/notification.service';
import { ImageTemplatesService, TemplateType } from 'app/service/image-templates/image-templates.service';
import { blobToFile } from 'app/utils/blob-to-file';
import { omitNullOrUndefined } from 'app/utils/operator/omit-null-or-undefined';
import { forkJoin, Observable, Observer, Subject, takeUntil } from 'rxjs';
import { BreakPoint, ScreenManagerService } from '../../../service/screen-manager/screen-manager.service';
import { IMAGES_EXTENSIONS } from '../../../utils/extensions';
import { DragAndDropFileUploadDirective } from '../../directives/drag-and-drop-file-upload.directive';
import { FileNameTruncatePipe } from '../../pipes/file-name-truncate.pipe';

@Component({
  selector: 'app-drag-and-drop-file',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DragAndDropFileUploadDirective,
    FileNameTruncatePipe,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    FlexModule,
    ImageTemplateSelectorModule,
  ],
  templateUrl: './drag-and-drop-file.component.html',
  styleUrls: ['./drag-and-drop-file.component.scss'],
})
export class DragAndDropFileComponent implements OnInit, OnDestroy {
  @Input() onlyEmit = false;
  @Input() previewVariant: DragAndDropFilePreviewVariant = 'grid';
  @Input() allowedExtensions: string[];
  @Input() allowedMIMETypes: string[];
  @Input() files: File[];
  @Input() lineHeight: number;
  @Input() title?: string;
  @Input() tooltip?: string;
  @Input() hasError = false;
  @Input() alreadyUploadedFiles: AlreadyUploadedFile[];
  @Input() mobileButtonText = 'Upload';
  @Input() maxWidth: number;
  @Input() maxHeight: number;
  @Input() maxFileSize: number;
  @Input() templateType: TemplateType = TemplateType.NONE;
  @Output() filesChange = new EventEmitter<File[]>();
  @Output() savedImagesClosed = new EventEmitter<void>();
  previewImages: PreviewImage[];
  alreadyUploaded: AlreadyUploadedItem[];
  showAlreadyUploaded = true;
  ltMd: boolean;

  noTemplateType = TemplateType.NONE;

  @ViewChild('file') fileInputElement: ElementRef;

  private _unsubscribeAll: Subject<void>;

  constructor(
    private screenManagerService: ScreenManagerService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private imageTemplateService: ImageTemplatesService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.subscribeToLtMd();
    this.lineHeight = this.getLineHeight();
    this.handleAlreadyUploaded();
    this.subscribeToTemplateSelect();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  handleOpenImageTemplateSelector(): void {
    this.imageTemplateService.openTemplateDialog(this.templateType);
  }

  private handleAlreadyUploaded(): void {
    if (!!this.alreadyUploadedFiles && this.alreadyUploadedFiles.length > 0) {
      this.alreadyUploaded = this.alreadyUploadedFiles.map((file) =>
        !!file.src ? this.processUrl(file.src) : this.processUploadedFiles(file)
      );
    } else {
      this.showAlreadyUploaded = false;
    }
  }

  private processUploadedFiles(file: AlreadyUploadedFile): AlreadyUploadedItem {
    const splitArray = file.filenameWithExtension.split('.');
    return {
      name: splitArray.slice(0, splitArray.length - 1).join('.'),
      extension: splitArray[splitArray.length - 1],
      icon: 'description',
    };
  }

  private processUrl(url: string): AlreadyUploadedItem {
    const [path, queryParams] = url.split('?');
    const splitByDot = path.split('.');
    const splitBySlash = splitByDot[splitByDot.length - 2].split('/');
    const extension = splitByDot[splitByDot.length - 1];
    return {
      url,
      extension,
      name: splitBySlash[splitBySlash.length - 1],
      queryParams,
      icon: !IMAGES_EXTENSIONS.map((ext) => ext.toUpperCase()).includes(extension.toUpperCase()) ? 'description' : null,
    };
  }

  private getLineHeight(): number {
    return this.lineHeight ?? (this.previewVariant === 'list' ? 150 : 120);
  }

  private getPreviewUrl(file: File, index: number): void {
    if (file.type.includes('image')) {
      this.getPreviewForImage(file, index);
    } else {
      this.previewImages[index] = {
        url: null,
        name: file.name,
        icon: 'description',
      };
    }
  }

  private getPreviewUrls(files: File[]): void {
    this.previewImages = new Array(files.length);
    files.forEach((file, index) => this.getPreviewUrl(file, index));
  }

  private getPreviewForImage(file: File, index: number): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.previewImages[index] = {
        url: reader.result as string,
        name: file.name,
      };
    };
  }

  private processFiles(list: FileList): File[] {
    const files = [];
    for (let i = 0; i < list.length; i++) {
      files.push(list.item(i));
    }
    return files;
  }

  private subscribeToLtMd(): void {
    this.screenManagerService
      .observeBreakpoint(BreakPoint.md)
      .pipe(this.screenManagerService.stateMatchesOperator())
      .subscribe((matches) => (this.ltMd = matches));
  }

  private checkFileExtension(files: File[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let count = 0;

      for (const file of files) {
        if (!this.allowedExtensions) {
          observer.next(true);
          observer.complete();
        }

        if (
          !this.checkMimeTypeMatch(file.type) &&
          !this.allowedExtensions.map((ext) => ext.toUpperCase()).includes(file.type.split('/')[1].toUpperCase())
        ) {
          observer.next(false);
          observer.complete();
        }

        count++;
        if (count === files.length) {
          observer.next(true);
          observer.complete();
        }
      }
    });
  }

  private checkMimeTypeMatch(type: string): boolean {
    return this.allowedMIMETypes.map((ext) => ext.toUpperCase()).includes(type.toUpperCase());
  }

  private checkFileSize(files: File[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let count = 0;

      for (const file of files) {
        if (!this.maxFileSize) {
          observer.next(true);
          observer.complete();
        }

        if (file.size > this.maxFileSize) {
          observer.next(false);
          observer.complete();
        }

        count++;
        if (count === files.length) {
          observer.next(true);
          observer.complete();
        }
      }
    });
  }

  private checkImageResolutions(files: File[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let count = 0;

      for (const file of files) {
        if (!file.type.includes('image')) {
          observer.next(true);
          observer.complete();
        }

        const img = new Image();
        img.src = window.URL.createObjectURL(file);

        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;

          if ((this.maxWidth && width > this.maxWidth) || (this.maxHeight && height > this.maxHeight)) {
            observer.next(false);
            observer.complete();
          }
          window.URL.revokeObjectURL(img.src);

          count++;
          if (count === files.length) {
            observer.next(true);
            observer.complete();
          }
        };
      }
    });
  }

  handleFileDropped(list: File[]): void {
    forkJoin([this.checkImageResolutions(list), this.checkFileSize(list), this.checkFileExtension(list)]).subscribe(
      ([imagesAreValid, fileSizesAreValid, extensionsAreValid]) => {
        if (!imagesAreValid) {
          this.notificationService.warning(
            this.translateService.instant('DRAG_AND_DROP_FILE.RESOLUTION_WARNING', {
              maxResolution: `${this.maxWidth}px x ${this.maxHeight}px`,
            })
          );
          this.fileInputElement.nativeElement.value = '';
        } else if (!fileSizesAreValid) {
          this.notificationService.warning(
            this.translateService.instant('DRAG_AND_DROP_FILE.SIZE_WARNING', {
              maxSize: `${this.maxFileSize / 1000000} MB`,
            })
          );
          this.fileInputElement.nativeElement.value = '';
        } else if (!extensionsAreValid) {
          this.notificationService.warning(
            this.translateService.instant('DRAG_AND_DROP_FILE.EXTENSION_WARNING', {
              allowed: this.allowedExtensions.join(', '),
            })
          );
          this.fileInputElement.nativeElement.value = '';
        } else {
          this.filesChange.emit(list);
          if (!this.onlyEmit) {
            this.getPreviewUrls(list);
          }
        }
      }
    );
  }

  deleteFile(index: number, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.previewImages.splice(index, 1);
    this.filesChange.emit(this.files.filter((item, i) => index !== i));
  }

  deleteAlreadyClicked(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.showAlreadyUploaded = false;
    this.savedImagesClosed.emit();
  }

  handleBrowserClick(element: HTMLInputElement): void {
    if (!this.hasUploadedFile && !this.showAlreadyUploaded) {
      element.click();
    }
  }

  handleBrowserFileChange(event: Event): void {
    this.handleFileDropped(this.processFiles(event.target['files']));
  }

  private subscribeToTemplateSelect(): void {
    if (this.templateType !== TemplateType.NONE) {
      this.imageTemplateService.selectedTemplate
        .pipe(takeUntil(this._unsubscribeAll), omitNullOrUndefined())
        .subscribe((res) => {
          this.handleFileDropped([blobToFile(res, `${crypto.randomUUID()}.png`)]);
        });
    }
  }

  get hasUploadedFile(): boolean {
    return this.files?.length > 0;
  }

  get hasAlreadyUploadedFile(): boolean {
    return this.alreadyUploadedFiles?.length > 0;
  }

  get accept(): string {
    return !!this.allowedExtensions ? this.allowedExtensions.map((ext) => `.${ext}`).join(',') : null;
  }

  get calculatedLineHeight(): number {
    return this.ltMd ? undefined : this.lineHeight;
  }
}

export type DragAndDropFilePreviewVariant = 'grid' | 'list';

interface PreviewImage {
  url: string;
  name: string;
  icon?: string;
}

interface AlreadyUploadedItem {
  name: string;
  extension: string;
  url?: string;
  queryParams?: any;
  icon?: string;
}

export interface AlreadyUploadedFile {
  src?: string;
  filenameWithExtension: string;
}
