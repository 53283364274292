const BASE_URL = 'https://backend.syncee.co';
const CONTEXT = '/rest/';

// Todo: Prod keys for paypal
export const environment = {
  production: true,
  maintenance: false,
  hmr: false,
  baseURL: BASE_URL,
  context: CONTEXT,
  fullBaseURL: 'https://backend.syncee.co',
  synceeImgURL: 'https://image.syncee.com',
  restURL: BASE_URL + CONTEXT,
  storageUrl: 'https://storage.googleapis.com/download/storage/v1/b/v5-stage-storage',
  assetsUrl: 'https://storage.googleapis.com/syncee-image-cdn/assets',
  synceeImageCdnUrl: 'https://storage.googleapis.com/syncee-image-cdn',
  cookieDomain: '.syncee.co',
  braintreeToken: 'production_gp246hpq_k9jt4ksqtz846rdj',

  alibabaAppKey: '32583301',
  paypal: {
    appid: 'AYoxf96I6MaXl1pwf3Q5m7_ooQtueKstclb3waMJVIbqlCfaCc58ivikfHhffttVX1LaUI421qBJiMZG',
    appIdV2: 'ASy7SPDvPFI_lCH3ZorYYVl97UJaaFZGvey4UkM_3DMkzbbQck1r6ERg6Li0NJ9E5fr7cx2CocuoNwa7',
    appIdV3: 'AQsqMuc4IR7ghs22hWi0AgfbqPvxaSyZzBmW5YAf2GBjnK_NYNICEXmESET9KcHgj8jPPEOXQHNHeSBN',
    authend: '',
    returnurl: 'https://syncee.com/supplier/storefront-settings/payments',
    gsRedirectUrl: 'https://syncee.com/getting-started/getting-paid?step=2',
    sandboxIdV2: 'AUpwKognyfW3uUS2bKxzkoKXxoiVd-7bJrmqG7gIxE2bLiqDKwc-glzjBqmScYiNFkOSMRlInfCokHZC',
  },
  stripe: {
    clientId: 'ca_JITcOgJdyCnMElM5ZEw02UCmIOBo476L',
    redirectUrlSettings:
      'https://syncee.com/supplier/storefront-settings/payments',
    gsRedirectUrl: 'https://syncee.com/getting-started/getting-paid/2',
    token: 'pk_live_leCGhedzotSYG9qt8H18qe0m',
    sandboxToken: 'pk_test_QoLfmPm7RTp3qb4W3SWHBP5w',
    saveCheckoutPaymentMethodSuccessURL: 'https://syncee.com/checkout/{checkoutId}/payment',
    settingsPaymentMethodSuccessURL: 'https://syncee.com/retailer/settings/payment-settings',
  },
  installerURL: 'https://installer.syncee.co',
  microservices: {
    productSearch: {
      baseUrl: 'https://product-search.syncee.co',
      serviceName: 'syncee-product-search'
    },
    chatService: {
      baseUrl: 'https://chat.v5.syncee.io',
      websocket: 'wss://chat.v5.syncee.io'
    },
    userService: {
      serviceName: 'syncee-user-service'
    },
    orderService: {
      serviceName: 'syncee-order-service'
    },
    taskService: {
      serviceName: 'syncee-task-service'
    },
    authService: {
      baseUrl: 'https://auth.syncee.io',
      serviceName: 'syncee-auth-service'
    },
    notificationService: {
      serviceName: 'syncee-notification-service'
    },
    paymentService: {
      serviceName: 'syncee-payment-service'
    },
    gatewayUrl: 'https://syncee-gateway.syncee.co'
  },
  googleClientId: '279362572859-4dh2tntqqmq114935ce9hl56uss0o6g9.apps.googleusercontent.com',
  facebookAppId: '3829311437144153',
  hasFreeTrial: true,
  hasWeeklyGame: true,
  hasCart: true,
  keyTokens: ';dPQ)WW}!x9NFN?]P/cG!E{RZy)ZujpQ(4Jz6e;zek;Q:d8S@FdqQ;q2L?ffhvQBQEx0D;_u]UVm(MiB;Jbw(B),)p',
  recaptchaSiteKey: '6LfIRcApAAAAAMvbO-E8S-Tafba8RPOKKVoVV9ec',
};


export const googleTagManagerId = 'GTM-MCPMF97M';
export const translationUrl = `https://storage.googleapis.com/download/storage/v1/b/v5-storage/o/translation%2F`;
