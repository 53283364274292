import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProductSearchState } from '../../../store/product-search/product-search.reducer';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { AddFilter } from '../../../store/product-search/product-search.action';
import { MarketplaceFilter } from '../../../vo/search-product-vo';
import { MatDialog } from '@angular/material/dialog';
import {
  FilterSelectorDialogComponent,
  FilterSelectorDialogData,
} from '../../../main/filter-common/filter-selector-dialog/filter-selector-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { BreakPoint } from '../../../service/screen-manager/screen-manager.service';
import { CurrencyService } from '../../../service/currency-service/currency.service';
import { PixelToSlideResponsive, SliderComponent } from '../slider/slider.component';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SliderItemComponent } from '../slider-item/slider-item.component';
import { MatButtonModule } from '@angular/material/button';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatCardModule } from '@angular/material/card';
import { FilterCommonModule } from '../../../main/filter-common/filter-common.module';
import { ExtendedModule } from '@angular/flex-layout';
import {
  FilterSelectorGtmService,
  PossibleFilters,
} from '../../../main/marketplace/marketplace-shared-components/service/filter-selector-gtm-service';
import { FilterActionKey } from '../../../service/google-tag-manager/actions/filter';
import { TranslateModule } from '@ngx-translate/core';
import { ProductSearchSortSelectorComponent } from '../product-search-sort-selector/product-search-sort-selector.component';
import { GTMFilterService } from '../../../main/marketplace/marketplace-shared-components/service/gtm-filter.service';

@Component({
  selector: 'app-filter-selector-row',
  templateUrl: './filter-selector-row.component.html',
  styleUrls: ['./filter-selector-row.component.scss'],
  imports: [
    MatIconModule,
    SliderComponent,
    CommonModule,
    SliderItemComponent,
    MatButtonModule,
    OverlayModule,
    MatCardModule,
    FilterCommonModule,
    ExtendedModule,
    TranslateModule,
    ProductSearchSortSelectorComponent,
  ],
  standalone: true,
})
export class FilterSelectorRowComponent implements OnInit, OnDestroy {
  @Input() hiddenFilters: (keyof MarketplaceFilter)[] = [];
  @Input() hideFilterDialog = false;
  @Input() supplierIdFilterList: number[];
  @Input() sendFilterToGtm = false;
  @Input() isSupplierSearch = false;
  productSearchStore$: Observable<ProductSearchState>;
  filter: MarketplaceFilter;
  filterDataChange = new Subject<MarketplaceFilter>();
  isSortOpen = false;
  nextSubject: Subject<void>;
  prevSubject: Subject<void>;
  prevAvailable = false;
  nextAvailable = false;
  pixelToSlide: PixelToSlideResponsive[] = [
    { breakpoint: BreakPoint.xl, pixelToSlide: 2000 },
    { breakpoint: BreakPoint.lg, pixelToSlide: 250 },
    { breakpoint: BreakPoint.md, pixelToSlide: 200 },
    { breakpoint: BreakPoint.sm, pixelToSlide: 100 },
  ];

  private filterStore$: Observable<MarketplaceFilter>;
  private unsubscribeAll: Subject<void>;

  constructor(
    public store: Store<AppState>,
    public filterSelectorGtmService: FilterSelectorGtmService,
    private dialog: MatDialog,
    private gtmFilterService: GTMFilterService,
    private currencyService: CurrencyService
  ) {
    this.gtmFilterService.position = 'FAVORITES';
    this.productSearchStore$ = this.store.pipe(select((state) => state.productSearch));
    this.filterStore$ = this.store.pipe(
      select((state) => state.productSearch),
      select((state) => state.filter)
    );
    this.unsubscribeAll = new Subject<void>();
    this.nextSubject = new Subject();
    this.prevSubject = new Subject();
  }

  ngOnInit(): void {
    this.subscribeToFilterDataChange();
    this.subscribeToFilterChange();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  handleChange(value: MarketplaceFilter): void {
    this.store.dispatch(new AddFilter({ value: { ...value } }));
  }

  handleClearFilter(filterKeys: string[]): void {
    const values: MarketplaceFilter = {};
    for (const key of filterKeys) {
      values[key] = null;
    }
    this.store.dispatch(new AddFilter({ value: values }));
  }

  openFilterDialog(): void {
    this.dialog.open<FilterSelectorDialogComponent, FilterSelectorDialogData>(FilterSelectorDialogComponent, {
      data: {
        filterDataChange: this.filterDataChange,
        filter: this.filter,
        hiddenFilters: this.hiddenFilters,
        sendFilterToGtm: true,
      },
      autoFocus: false,
      restoreFocus: false,
    });
  }

  slidePrev(): void {
    this.prevSubject.next();
  }

  slideNext(): void {
    this.nextSubject.next();
  }

  setGtmFilterValue(key: FilterActionKey, value: PossibleFilters): void {
    if (this.sendFilterToGtm) {
      this.filterSelectorGtmService.setFilter(key, value);
    }
  }

  private subscribeToFilterDataChange(): void {
    this.filterDataChange.subscribe({
      next: (value) => {
        this.store.dispatch(new AddFilter({ value: value }));
      },
    });
  }
  private subscribeToFilterChange(): void {
    this.filterStore$.pipe(takeUntil(this.unsubscribeAll)).subscribe((filter) => {
      this.filter = filter;
    });
  }
}
